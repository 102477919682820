<script>
import router from "../../router/routes";
import NavBar from "@/components/nav-bar";
import SideBar from "@/components/side-bar";
import Footer from "@/components/footer";
import { api } from "@/state/services";

export default {
  components: { NavBar, SideBar, Footer },
  beforeCreate: function () {
    document.body.className = "main";
  },
  data() {
    return {
      isMenuCondensed: false,

      membership: {
        order: {
          id: null,
        }
      }
    };
  },
  created: () => {
    document.body.removeAttribute("data-layout", "horizontal");
    document.body.removeAttribute("data-topbar", "dark");
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle("sidebar-enable");

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove("sidebar-enable");
        });
      }
      this.isMenuCondensed = !this.isMenuCondensed;
    },
    toggleRightSidebar() {
      document.body.classList.toggle("right-bar-enabled");
    },
    hideRightSidebar() {
      document.body.classList.remove("right-bar-enabled");
    },
    getMembership() {
      api
        .get("membership")
        .then((response) => {
          if (response.data.status == "success") {
            if (response.data.membership.status == 'pending') {
              this.membership.order.id = response.data.membership.order.id
            }
          }
        })
    },
  },
  mounted() {
    this.getMembership();
  },
};
</script>

<template>
  <div id="layout-wrapper">
    <NavBar :isCondensed="isMenuCondensed" />
    <SideBar :isCondensed="isMenuCondensed" />
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <router-link v-if="membership.order.id" :to="'/store/order/' + membership.order.id" class="d-block mb-4 bg-soft-info p-3 rounded text-dark">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
              <path d="M12 10c1.151 0 2-.848 2-2s-.849-2-2-2c-1.15 0-2 .848-2 2s.85 2 2 2zm0 1c-2.209 0-4 1.612-4 3.6v.386h8V14.6c0-1.988-1.791-3.6-4-3.6z"></path>
              <path d="M19 2H5c-1.103 0-2 .897-2 2v13c0 1.103.897 2 2 2h4l3 3 3-3h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm-5 15-2 2-2-2H5V4h14l.002 13H14z"></path>
            </svg>
            <span class="ml-2">
              Faça sua ativação mensal e participe de todos os benefícios do clube.
            </span>
          </router-link>
          <slot />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
